exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-technovazinc-jsx": () => import("./../../../src/pages/technovazinc.jsx" /* webpackChunkName: "component---src-pages-technovazinc-jsx" */),
  "component---src-templates-vacancies-city-name-tsx": () => import("./../../../src/templates/vacancies/[cityName].tsx" /* webpackChunkName: "component---src-templates-vacancies-city-name-tsx" */)
}

