import { createTheme } from '@material-ui/core';
import AeroportTtf from 'static/fonts/Aeroport-regular.ttf';

const aeroport = {
  fontFamily: 'Aeroport',
  src: `url(${AeroportTtf})`,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#D43E17',
    },
    secondary: {
      main: '#9B9D8F',
    },
    action: {
      disabled: '#3A373B',
    },
    grey: {
      box: '#121212',
      light: '#414141',
      dark: '#1C1C1C',
      text: '#3A373B',
    },
    beige: {
      main: '#9B9D8F',
    },
    blue: {
      main: '#76A1C0',
    },
  },
  typography: {
    fontFamily: 'Aeroport, sans-serif',
    h2: {
      fontSize: 48,
    },
    h3: {
      fontSize: 24,
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [aeroport],
        button: {
          outline: 'none',
        },
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: 'transparent',
      },
    },
    MuiButtonBase: {
      root: {
        '&$root': {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      root: {
        '&$root': {
          color: '#3a373b',
        },
        '&$selected': {
          color: '#D43E17',
        },
      },
    },
  },
});

export default theme;
